<template>
  <div class="setting">
    <div class="time">
      <div class="left">
        <div class="totally mainColor">
          {{ $t("TotallyTime") }}{{ $t("colon") }} {{ howHour }}H
        </div>
        <div class="prompt">
          {{ $t("10hournotisdonsnegination") }}
        </div>
      </div>
      <div class="right">
        <el-button @click="openAddSet" size="small" type="primary"
          >Set available time</el-button
        >
      </div>
    </div>
    <div class="table-wrap tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column
          prop="date"
          :label="$t('Negotiationdate')"
          min-width="80px"
        >
          <template slot-scope="scope">
            {{ scope.row.day | secondFormat("L") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('Negotiationtime')"
          min-width="80px"
        >
          <template slot-scope="scope">
            {{ scope.row.start_time | secondFormat("T") }} -
            {{ scope.row.end_time | secondFormat("T") }}
          </template>
        </el-table-column>
        <el-table-column prop="date" :label="$t('handle')" width="200px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.end_time > nowTime"
              type="text"
              @click="editItem(scope.row)"
              >{{ $t("edit") }}</el-button
            >
            <span v-if="scope.row.end_time > nowTime" class="line1">|</span>
            <el-button type="text" @click="openDeleted(scope.row)">{{
              $t("delete")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      v-if="deletedDialog"
      :visible.sync="deletedDialog"
      width="400px"
      class="deleteddialogclass"
    >
      <div>{{ $t("invitationsyouhavealread") }}</div>
      <div class="btns tr">
        <el-button size="small" @click="deletedDialog = false">{{
          $t("cancel")
        }}</el-button>
        <el-button
          :loading="deletedLoading"
          size="small"
          type="primary"
          @click="deleteSomeItem"
          >{{ $t("delete") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :title="setTitle"
      v-if="setNegotiation"
      :visible.sync="setNegotiation"
      width="600px"
    >
      <addSetting
        :item="currentItem"
        @closeDialog="closeDialog"
        v-if="setNegotiation"
      />
    </el-dialog>
  </div>
</template>

<script>
import addSetting from "./components/addSetting";
export default {
  components: {
    addSetting,
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      tableData: [],
      tableHeight: 100,
      loading: false,
      deletedDialog: false,
      setNegotiation: false,
      currentItem: {},
      deletedLoading: false,
      howHour: null,
    };
  },
  computed: {
    setTitle() {
      let num = 0 - new Date().getTimezoneOffset() / 60;
      if (num > 0) {
        num = "+" + num;
      }
      let utc = "UTC" + num;
      return `Set available time     ${utc}`;
    },
    nowTime() {
      return moment().unix();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {};
      params.meeting_id = this.MEETING_ID;
      params.user_id = this.USER_INFO.id;
      params.nopage = 0;
      params.start = this.currentPage - 1;
      params.limit = 10;
      let res = await this.$store.dispatch(
        "baseConsole/getnegotationsetList",
        params
      );
      if (res.success) {
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.count;
        this.howHour = (res.total_time / 3600).toFixed(1);
        this.$store.commit("SET_TALK_DURATION", this.howHour);
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    openAddSet() {
      this.currentItem = {};
      this.setNegotiation = true;
    },
    closeDialog(value) {
      this.setNegotiation = false;
      if (value) {
        this.getList();
      }
    },
    openDeleted(item) {
      this.deletedDialog = true;
      this.currentItem = item;
    },
    async deleteSomeItem() {
      this.deletedLoading = true;
      let params = {};
      params.set_type = "delete";
      params.meeting_id = this.MEETING_ID;
      params.user_id = this.USER_ID;
      params.setting_id = this.currentItem.id;
      let res = await this.$store.dispatch("baseConsole/setsetupTime", params);
      if (res.success) {
        console.log(res);
        this.deletedLoading = false;
        this.deletedDialog = false;
        //判断请求回来的数据如果为空，且当前页数不为第一页时，分页-1重新请求
        if (this.tableData.length == 1 && this.currentPage != 1) {
          this.currentPage--;
        }
        this.getList();
      }
    },
    editItem(item) {
      this.currentItem = item;
      this.setNegotiation = true;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.time {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .totally {
      color: #333;
      margin-right: 20px;
      font-weight: bold;
    }
    .prompt {
      color: #7f7f7f;
    }
  }
}
.line1 {
  margin: 0 10px;
  color: #027fff;
}
.btns {
  margin-top: 20px;
}
.deleteddialogclass {
  /deep/ .el-dialog__header {
    border-bottom: none;
  }
  /deep/ .el-dialog__body {
    padding-top: 10px;
  }
}
</style>
