<template>
  <div class="wrap">
    <el-form
      size="small"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('time')" prop="date">
        <el-date-picker
          v-model="ruleForm.date"
          type="date"
          :placeholder="$t('Setsomedate')"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="end_time" class="time">
        <template slot="label">
          <div class="labelSlot">{{$t('times')}}</div>
        </template>
        <el-time-select
          v-model="ruleForm.start_time"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:00',
            minTime:start_begin
          }"
          size="small"
          format="HH:mm"
          value-format="HH:mm"
          :placeholder="$t('beginTime')"
          @change="handleStartTime"
        >
        </el-time-select>
        <span>-</span>
        <el-time-select
          size="small"
          :placeholder="$t('endTime')"
          v-model="ruleForm.end_time"
          format="HH:mm"
          value-format="HH:mm"
          :picker-options="{
            start: '00:30',
            step: '00:30',
            end: '23:30',
            minTime: date,
          }"
        >
        </el-time-select>
      </el-form-item>
    </el-form>
    <div class="btns tr">
      <el-button size="small" @click="clase">{{$t('cancel')}}</el-button>
      <el-button :loading="loading" size="small" type="primary" @click="save('ruleForm')"
        >{{$t('add')}}</el-button
      >
    </div>
  </div>
</template>

<script>
import { secondFormat } from "~/basePlugins/filters";
export default {
  props: {
    item:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  data() {
    return {
      pickerOptions:{

      },
      start_begin:null,
      loading:false,
      ruleForm: {
        date: null,
        start_time: null,
        end_time: null,
      },
      rules: {
        date: {
          required: true,
          message: " ",
          trigger: blur,
        },
        end_time:{
          validator:(rule,value,callback)=>{
            if(!this.ruleForm.start_time||!this.ruleForm.end_time){
              callback(new Error(" "));
            }else{
              callback();
            }
          },
          trigger: blur
        }

      },
      date: null,
    };
  },
  mounted() {
    let start_time=this.$store.state.baseStore.meetingInfo.start_time*1000
    let now_time=new Date().getTime()-86400*1000
    let begin_time=now_time>start_time?now_time:start_time
    let end_time=this.$store.state.baseStore.meetingInfo.end_time*1000
    this.pickerOptions={
      disabledDate(time) {
        return time.getTime() <begin_time || time.getTime() > end_time;
      },
    }

    if(this.item.id){
      this.ruleForm.date=moment.unix(this.item.day)
      this.ruleForm.start_time=secondFormat(this.item.start_time,"T")
      this.ruleForm.end_time=secondFormat(this.item.end_time,"T")
    }
  },
  watch:{
    "ruleForm.date"(e){
      console.log(typeof(e), e);
      if(e._d){
        if(e._d.getDate()==new Date().getDate()){
          this.start_begin=secondFormat(moment(new Date()).unix(), 'T')
        }else{
          this.start_begin=null
        }
      }else{
        if(e.getDate()==new Date().getDate()){
          this.start_begin=secondFormat(moment(new Date()).unix(), 'T')
        }else{
          this.start_begin=null
        }
      }


      
    }
  },
  methods: {
    
    handleStartTime(val) {
      this.ruleForm.end_time = null;
      let data = val;
      let h = Number(data.split(":")[0]);
      let m = Number(data.split(":")[1]);
      let s = Number(h * 3600) + Number(m * 60);
      let day = moment().startOf("day").unix();
      let date = s + day + 1740;
      this.date = secondFormat(date, "T");
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveFn()
        } else {
          return false;
        }
      });
    },
    async saveFn(){
      this.loading = true
      let params={}
      if(this.item.id){
        params.set_type="update"
        params.setting_id=this.item.id
      }else{
      params.set_type="add"
      }
      params.meeting_id=this.MEETING_ID
      params.user_id=this.USER_ID
      params.day=moment(this.ruleForm.date).unix()
      params.start_time=this.secondComputed(this.ruleForm.start_time)
      +moment(this.ruleForm.date).unix()
      params.end_time=this.secondComputed(this.ruleForm.end_time)
      +moment(this.ruleForm.date).unix()
      let res= await this.$store.dispatch("baseConsole/setsetupTime",params)
      if(res.success){
        if(res.result===false){
          this.$message.warning(this.$t('Repetitivetimepleasesetupagain'))
          this.loading = false
        }else{
          this.loading = false
          this.$emit("closeDialog",1);
        }
      }

    },
    secondComputed(value){
      let hour = Number(value.split(":")[0]);
      let min = Number(value.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      return s
    },
    clase() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="less" scoped>
.time {
  .el-date-editor {
    width: 50%;
  }
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>